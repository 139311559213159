html, body, #root{ height: 100%; }

.section-heading {
  font-size: 38px;
}
@media (max-width: 991px) {
  .section-heading {
    font-size: 32px;
  }
}
@media (max-width: 575px) {
  .section-heading {
    font-size: 28px;
  }
}

.negative-margin {
  margin-top: -20vh;
}

.cta-button {
  width:100%;
  max-width: 200px;
  border: 2px solid #47F4FF;
  border-radius: 15px;
  padding: 10px;
}
.cta-button p {
  color: #47F4FF;
  font-size: 18px;
}
.cta-button:hover {
  transition: 0.5s;
  background-color: #47F4FF;
}
.cta-button:hover p {
  transition: 0.5s;
  color: #000000;
}

/* Background Music */
.sound-btn {
  position: fixed;
  z-index:20;
  cursor:pointer;
  text-align:center;
  right:1.2rem;
  bottom:2rem;
  background-color: #1e1e1e;
  border: 2px solid #0030FF;
  border-radius: 50%;
}

.music-icon {
  color:#DCDCDC;
  margin-left: 1px;
}
.sound-icon:hover{scale:1.1; transition: 0.5s;}
/******** 768 - 1024 ********/
@media (max-width: 1024px) {
  .sound-icon{width: 55px;}
}
/******** 425 - 767 ********/
@media (max-width: 767px) {
  .sound-btn{right:0.7rem; bottom:0.7rem;}
}



/* Header */
.header {
  background-color: #000000;
  padding: 5px 0px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.header-logo {
  width: 180px;
}
@media (max-width: 575px) {
  .header-logo {
    width: 140px;
  }
}

.header-button {
  max-width: 180px;
  border: 2px solid #0030FF;
  background-color: #0030FF;
  border-radius: 15px;
  padding: 10px 30px;
  margin-top: 10px;
}
@media (max-width: 575px) {
  .header-button {
    padding: 5px 15px;
    border-radius: 10px;
    max-width: 160px;
  }
}
.header-button p {
  color: #DCDCDC;
  font-size: 18px;
}
@media (max-width: 575px) {
  .header-button p {
    font-size: 16px;
  }
}
.header-button:hover {
  transition: 0.5s;
  background: #001c99;
  border: 2px solid #001c99;
}







/* Hero Section */
.hero-wrapper {
  position: relative;
  width: 100%;
  min-height: 125vh;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-text-wrapper {
  text-align: center;
	position: absolute;
	top: 40%;
	transform: translateY(-50%);
}
@media (max-width: 767px) {
  .hero-text-wrapper {
    top: 35%;
  }
}

.hero-heading-1 {
  font-size: 80px;
}
.hero-heading-2 {
  margin-top: -20px;
  font-size: 130px;
}
@media (max-width: 1199px) {
  .hero-heading-1 {
    font-size: 50px;
  }
  .hero-heading-2 {
    font-size: 80px;
  }
}
@media (max-width: 767px) {
  .hero-heading-1 {
    font-size: 36px;
  }
  .hero-heading-2 {
    margin-top: -10px;
    font-size: 56px;
  }
}
@media (max-width: 424px) {
  .hero-heading-1 {
    font-size: 28px;
  }
  .hero-heading-2 {
    font-size: 42px;
  }
}

.mouse-scroll {
  font-size: 36px;
}
@media (max-width: 767px) {
  .mouse-scroll {
    font-size: 32px;
  }
}






/* Main Content Area */
.main-content {
  margin-top: 75px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .main-content {
    margin-top: 55px;
  }
}





/* Main USP */
.main-usp {
  border-top: 2px solid #47F4FF;
  background-color: #000000;
}

.usp-wrapper {
  background-color: #000000;
  border: 2px solid #47F4FF;
  border-radius: 15px;
  padding: 20px;
  min-height: 250px;
}
@media (max-width: 991px) {
  .usp-wrapper {
    /* height: auto; */
    min-height: auto;
  }
}

.usp-icon {
  font-size: 42px;
  color: #47F4FF;
}

.usp-heading {
  font-size: 22px;
}



/* Brands & Logos */
.brands-section {
  background-color: #000000;
  padding-bottom: 40px;
}

.brand-logo {
  width: 100%;
  max-width: 200px;
}
@media (max-width: 575px) {
  .brand-logo {
    max-width: 180px;
  }
}



/* Featured Slider */
.featured-slider {
  margin-top: 50px;
  background: rgb(239,239,239);
  background: -moz-linear-gradient(180deg, rgba(239,239,239,1) 25%, rgba(141,243,250,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(239,239,239,1) 25%, rgba(141,243,250,1) 100%);
  background: linear-gradient(180deg, rgba(239,239,239,1) 25%, rgba(141,243,250,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#efefef",endColorstr="#8df3fa",GradientType=1);
}
@media (max-width: 767px) {
  .featured-slider {
    margin-top: 30px;
  }
}
.slider-img {
  width: 100%;
  min-width: 200px;
  border-radius: 10px;
}


.portfolio-section {
  background: rgb(141,243,250);
  background: -moz-linear-gradient(180deg, rgba(141,243,250,1) 30%, rgba(69,192,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(141,243,250,1) 30%, rgba(69,192,255,1) 100%);
  background: linear-gradient(180deg, rgba(141,243,250,1) 30%, rgba(69,192,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8df3fa",endColorstr="#45c0ff",GradientType=1);
  min-height: 100vh;
}

.portfolio-container {
  background-color: #e3e3e3;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.portfolio-container:hover {
  scale: 1.03;
  transition: 0.8s;
  cursor: pointer;
}

.portfolio-cover {
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.portfolio-details {
  background: rgb(0,10,105);
  background: -moz-linear-gradient(180deg, rgba(0,10,105,1) 0%, rgba(24,143,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0,10,105,1) 0%, rgba(24,143,255,1) 100%);
  background: linear-gradient(180deg, rgba(0,10,105,1) 0%, rgba(24,143,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000a69",endColorstr="#188fff",GradientType=1);
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}




.data-section {
  background: rgb(20,14,14);
  background: -moz-linear-gradient(180deg, rgba(20,14,14,1) 16%, rgba(47,26,88,1) 90%);
  background: -webkit-linear-gradient(180deg, rgba(20,14,14,1) 16%, rgba(47,26,88,1) 90%);
  background: linear-gradient(180deg, rgba(20,14,14,1) 16%, rgba(47,26,88,1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#140e0e",endColorstr="#2f1a58",GradientType=1);
}

.data-icon {
  font-size: 56px;
}

.data-numbers {
  font-family: 'helvetica';
  font-size: 38px;
  font-weight: 700;
  letter-spacing: 2px;
}


.build-with-us {
  min-height: 300px;
  padding: 80px 10px;
  background-image: url(../img/dev-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.build-wrapper {
  background-color: #000000d1;
  border-radius: 15px;
  padding: 20px;
}

.build-heading {
  font-size: 22px;
}





.community-section {
  background-color: #000;
  padding: 60px 10px;
}

.social-link {
  color: #DCDCDC;
  text-decoration: none;
}
.social-link:hover {
  color: #0030FF;
}

.social-icon {
  color: #DCDCDC;
  font-size: 36px;
}
.social-icon:hover {
  transition: 0.5s;
  cursor: pointer;
  scale: 1.05;
}






/* Footer */
.footer {
  background-color: #111111;
}
.copyright-text {
  font-size: 14px;
  color: #DCDCDC;
}




