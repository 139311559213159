@font-face {
    font-family: 'mono-bold';
    src: url('../fonts/mono-bold-webfont.woff2') format('woff2'),
         url('../fonts/mono-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica';
    src: url('../fonts/helvetica-webfont.woff2') format('woff2'),
         url('../fonts/helvetica-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-regular';
    src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.blue {color:#0030FF;}
.light-blue {color:#47F4FF;}
.dark-blue {color:#00146b;}

.text-gray {color:#dcdcdc;}

.neon-green {color:#1CFFD2;}

.pastel-green {color:#bdffe4;}
.pastel-purple {color:#CDABEB;}
.pastel-blue {color:#9dcff9;}

.profit-green {color:#48b30e;}
.error {color:#e10909;}

html {
    scroll-behavior: smooth;
}

body {
    margin:0;
    padding:0;
    overflow-x: hidden;
    overflow-y: scroll; 
    background-color: #EFEFEF;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    scroll-behavior: smooth;
}
body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

h1, h2, h3, h4, h5, h6 {font-family: 'mono-bold';}

h3 {font-size: 24px;}
@media (max-width: 991px) {
    h3 {font-size: 20px;}
}

p {font-family: 'roboto-regular';}

/* Mobile Version */
@media (max-width: 767px) {
    .mobile-none {
        display: none;
    }
}
@media (min-width: 768px) {
    .desktop-none {
        display: none;
    }
}

.fluid-padding {
    padding-left: 15px;
    padding-right: 15px;
}
@media (min-width: 1200px) {
    .fluid-padding {
        padding-left: 100px;
        padding-right: 100px;
    }
}
@media (min-width: 1600px) {
    .fluid-padding {
        padding-left: 230px;
        padding-right: 230px;
    }
}
@media (min-width: 2000px) {
    .fluid-padding {
        padding-left: 300px;
        padding-right: 300px;
    }
}
@media (min-width: 2560px) {
    .fluid-padding {
        padding-left: 350px;
        padding-right: 350px;
    }
}